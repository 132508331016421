import {
  ClientStage,
  ConfigLoader,
  OfflineClientSettings
} from "@amzn/amazon-config-store-type-script-offline-adapter";
import { OfflineConfigManager } from "@amzn/amazon-config-store-type-script-offline-adapter";
import { TypeSafeClientProvider } from "@amzn/amazon-config-store-type-script-client";

const isProd = (stage: string) => stage === "prod";
const configManager = new OfflineConfigManager();

export function provideClient<T>(
  clientProvider: TypeSafeClientProvider<T>,
  stage: string,
  prodConfig: ConfigLoader,
  betaConfig: ConfigLoader
): T {
  return configManager.createClient(
    clientProvider,
    isProd(stage) ? prodConfig : betaConfig,
    new OfflineClientSettings({
      stage: isProd(stage) ? ClientStage.PROD : ClientStage.DEVO
    })
  );
}
