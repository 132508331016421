import { ConfigLoader } from "@amzn/amazon-config-store-type-script-offline-adapter";
import { provideClient } from "./AcsClientHelper";
import {
  RetailRegionToMarketplaceIdClientProvider,
  RetailRegionToMarketplaceIdContext
} from "@amzn/retail-region-to-marketplace-id-type-script-client/dist/retailRegionToMarketplaceId";
import {
  MarketplaceConfigClientProvider,
  MarketplaceConfigContext
} from "@amzn/marketplace-config-type-script-client/dist/marketplaceConfig";
import { documents as MarketplaceConfigProdDocuments } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_MarketplaceConfig/documents.json";
import { queries as MarketplaceConfigProdExactQueries } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_MarketplaceConfig/queries.json";
import { queries as MarketplaceConfigProdHfQueries } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_MarketplaceConfig/hierarchical_queries.json";
import { documents as MarketplaceConfigBetaDocuments } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/devo/acs_MarketplaceConfig/documents.json";
import { queries as MarketplaceConfigBetaExactQueries } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/devo/acs_MarketplaceConfig/queries.json";
import { queries as MarketplaceConfigBetaProdHfQueries } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/devo/acs_MarketplaceConfig/hierarchical_queries.json";
import { documents as RegionToMarketplaceIdProdDocuments } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_RetailRegionToMarketplaceId/documents.json";
import { queries as RegionToMarketplaceIdProdQueries } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_RetailRegionToMarketplaceId/queries.json";
import { queries as RegionToMarketplaceIdProdHfQueries } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/acs_RetailRegionToMarketplaceId/hierarchical_queries.json";
import { documents as RegionToMarketplaceIdBetaDocuments } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/devo/acs_RetailRegionToMarketplaceId/documents.json";
import { queries as RegionToMarketplaceIdBetaQueries } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/devo/acs_RetailRegionToMarketplaceId/queries.json";
import { queries as RegionToMarketplaceIdBetaHfQueries } from "@amzn/amazon-config-store-type-script-offline-adapter/dist/configuration/devo/acs_RetailRegionToMarketplaceId/hierarchical_queries.json";

const marketplaceProdConfig: ConfigLoader = {
  load: () => ({
    documents: MarketplaceConfigProdDocuments,
    exactQueries: MarketplaceConfigProdExactQueries,
    hierarchicalFallbackQueries: MarketplaceConfigProdHfQueries
  })
};
const marketplaceBetaConfig: ConfigLoader = {
  load: () => ({
    documents: MarketplaceConfigBetaDocuments,
    exactQueries: MarketplaceConfigBetaExactQueries,
    hierarchicalFallbackQueries: MarketplaceConfigBetaProdHfQueries
  })
};

const regionToMarketplaceIdConfigProd: ConfigLoader = {
  load: () => ({
    documents: RegionToMarketplaceIdProdDocuments,
    exactQueries: RegionToMarketplaceIdProdQueries,
    hierarchicalFallbackQueries: RegionToMarketplaceIdProdHfQueries
  })
};
const regionToMarketplaceIdConfigBeta: ConfigLoader = {
  load: () => ({
    documents: RegionToMarketplaceIdBetaDocuments,
    exactQueries: RegionToMarketplaceIdBetaQueries,
    hierarchicalFallbackQueries: RegionToMarketplaceIdBetaHfQueries
  })
};

const regionToMarketplaceConfigClient = (stage: string) =>
  provideClient(
    new RetailRegionToMarketplaceIdClientProvider(),
    stage,
    regionToMarketplaceIdConfigProd,
    regionToMarketplaceIdConfigBeta
  );

const marketplaceConfigClient = (stage: string) =>
  provideClient(
    new MarketplaceConfigClientProvider(),
    stage,
    marketplaceProdConfig,
    marketplaceBetaConfig
  );

const getMarketplaceConfig = (stage: string, marketplaceId: string) =>
  marketplaceConfigClient(stage).getMarketplace(
    new MarketplaceConfigContext(marketplaceId)
  );

export const getCountryCode = (stage: string, marketplaceId: string) =>
  getMarketplaceConfig(stage, marketplaceId)?.defaultCountryCode;

export const getRegion = (stage: string, marketplaceId: string) =>
  getMarketplaceConfig(stage, marketplaceId)?.defaultRegion;

export const getMarketplaceIds = (stage: string, regionCode: string) =>
  regionToMarketplaceConfigClient(stage).getMarketplaceIds(
    new RetailRegionToMarketplaceIdContext(regionCode)
  );
