import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KatDataTable } from "@amzn/katal-react";
import { StageContext } from "../../App";
import { fetchAllPrograms } from "../../backend/http";
import { ProgramData } from "../../common/Program";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import {
  SearchProgramBar,
  SearchProgramBarData
} from "../SearchProgramBar/SearchProgramBar";

export function ListPrograms(): JSX.Element {
  const { t } = useTranslation();
  const stage = useContext(StageContext);
  const [programList, onProgramListChange] = useState<
    ProgramData[] | undefined
  >(undefined);
  const [region, setRegion] = useState("");

  const handleSearchAllPrograms = (data: SearchProgramBarData) => {
    onProgramListChange(undefined);
    const { programId, ...fetchAllProgramsParams } = data;
    return fetchAllPrograms(fetchAllProgramsParams, stage)
      .then(response => response.data as ProgramData[])
      .then(onProgramListChange)
      .finally(() => setRegion(data.region));
  };

  const calculatedColumns = useMemo(
    () =>
      Object.keys(programList ? programList[0] : []).map(key => ({
        label: capitalizeFirstLetter(key),
        property: key
      })),
    [programList]
  );

  return (
    <>
      <SearchProgramBar
        handleSearchProgram={handleSearchAllPrograms}
        withProgramId={false}
        title={t("list-title-label")}
      />

      {programList && (
        <KatDataTable
          id="listProgramsDataTable"
          titleLabel={`${capitalizeFirstLetter(programList[0].program)} ${t(
            "data-label"
          )} ${region}`}
          columns={calculatedColumns}
          rowData={programList}
        />
      )}
    </>
  );
}
