import classNames from "classnames";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  KatAlert,
  KatButton,
  KatDropdown,
  KatIcon,
  KatInput,
  KatModal,
  KatStatusindicator
} from "@amzn/katal-react";
import { StageContext } from "../../App";
import { ErrorProps } from "../../common/Error";
import {
  marketplacesFromProgram,
  programsFromMarketplace
} from "../../common/Marketplace";
import { emptyProgramData, ProgramData } from "../../common/Program";
import { AssociationInput } from "../AssociationInput/AssociationInput";
import { CopyLink } from "../CopyLink/CopyLink";
import { CopyTooltip } from "../CopyTooltip/CopyTooltip";
import styles from "./ProgramModalForm.module.css";
import { AssociationNameModal } from "../AssociationNameModal/AssociationNameModal";

const ASSOCIATION_PROGRAM = "association";
const FORM_STYLE = "kat-col-xs-6";

export interface ProgramModalFormProps {
  isUpdate: boolean;
  isEnableModal: boolean;
  onChangeEnableModal: (value: boolean) => void;
  prefilledProgramData: ProgramData;
  principalEventHandler: (programData: ProgramData) => Promise<string>;
}

export function ProgramModalForm(props: ProgramModalFormProps): JSX.Element {
  const { t } = useTranslation();
  const stage = useContext(StageContext);
  const [attributesFilled, onAttributesFilledChange] = useState(false);
  const [
    isEnableAssociationNameModal,
    onIsEnableAssociationNameModal
  ] = useState(false);
  const [error, onErrorChange] = useState<ErrorProps | undefined>(undefined);
  const [isLoadingEnabled, onIsLoadingEnabledChange] = useState(false);
  const [showUrlUpdated, showUrlUpdatedChange] = useState(false);
  const [programData, onProgramDataChange] = useState<ProgramData>(
    props.prefilledProgramData
  );

  const marketplaceForProgramStaged = useMemo(
    () => marketplacesFromProgram(stage, programData.program),
    [programData.program, stage]
  );
  const programsForMarketplace = useMemo(
    () => programsFromMarketplace(stage, programData.marketplaceId),
    [programData.marketplaceId, stage]
  );

  const isProgramDropdownDisabled =
    programsForMarketplace.length === 0 || props.isUpdate;
  const isProgramIdInputDisabled = !programData.program || props.isUpdate;
  const statusLabel = props.isUpdate
    ? t("success-update-message")
    : t("success-create-message");
  const mode = props.isUpdate ? t("update-label") : t("create-label");

  const handleOnChangeMarketplaceDropdown = event => {
    const retainProgramData = marketplaceForProgramStaged
      .map(item => item.value)
      .includes(event.detail.value)
      ? programData
      : emptyProgramData;
    onProgramDataChange({
      ...retainProgramData,
      marketplaceId: event.detail.value
    });
  };

  const handleOnChangeProgramDropdown = event =>
    onProgramDataChange({
      ...emptyProgramData,
      marketplaceId: programData.marketplaceId,
      program: event.detail.value
    });

  const handleOnInputProgramId = event =>
    onProgramDataChange({
      ...programData,
      programId: event.target.value.trim()
    });

  const isUpdateFormChanged = () => {
    const { entryPoint: currentEntry, ...currentProgram } = programData;
    const { entryPoint, ...oldProgram } = props.prefilledProgramData;
    return !(JSON.stringify(oldProgram) === JSON.stringify(currentProgram));
  };

  const isPrincipalButtonDisabled = !(
    attributesFilled &&
    programData.programId &&
    isUpdateFormChanged()
  );

  const handleClearEvent = () => {
    onErrorChange(undefined);
    onProgramDataChange(
      props.isUpdate ? props.prefilledProgramData : emptyProgramData
    );
    showUrlUpdatedChange(false);
  };

  const handleModalClose = () => {
    props.onChangeEnableModal(false);
    handleClearEvent();
  };

  const performCreateProgramEvent = () => {
    onErrorChange(undefined);
    showUrlUpdatedChange(false);
    onIsLoadingEnabledChange(true);
    onProgramDataChange({
      ...programData,
      entryPoint: ""
    });
    props
      .principalEventHandler(programData)
      .then(response => {
        onProgramDataChange({
          ...programData,
          entryPoint: response
        });
        showUrlUpdatedChange(true);
      })
      .catch(error => {
        onErrorChange({
          statusCode: error.response.status,
          message: error.response.data.error
        });
      })
      .finally(() => onIsLoadingEnabledChange(false));
  };

  const handlePrincipalEvent = () => {
    if (programData["associationName"]) onIsEnableAssociationNameModal(true);
    else performCreateProgramEvent();
  };

  const handleAssociationNameModalClose = () => {
    onIsEnableAssociationNameModal(false);
  };

  const handleAssociationNameModalSubmit = () => {
    onIsEnableAssociationNameModal(false);
    performCreateProgramEvent();
  };

  return (
    <KatModal
      id="programModal"
      className={classNames(styles.modal)}
      visible={props.isEnableModal}
      onClose={handleModalClose}
    >
      <span slot="title">
        {mode} {t("program-name") as string}
      </span>
      <div className={classNames("kat-row")}>
        <KatDropdown
          id="consoleMarketplaceDropdown"
          className={classNames(FORM_STYLE)}
          label={t("marketplace-dropdown-label")}
          options={marketplaceForProgramStaged}
          value={programData.marketplaceId}
          onChange={handleOnChangeMarketplaceDropdown}
        />
        <KatDropdown
          id="consoleProgramDropdown"
          className={classNames(FORM_STYLE)}
          label={t("program-type-dropdown-label")}
          options={programsForMarketplace}
          disabled={isProgramDropdownDisabled}
          value={programData.program}
          onChange={handleOnChangeProgramDropdown}
        />
        <KatInput
          id="consoleProgramIdInput"
          className={classNames(FORM_STYLE)}
          label={`${t("programid-input-label")} *`}
          type="search"
          disabled={isProgramIdInputDisabled}
          value={programData.programId}
          onInput={handleOnInputProgramId}
        />

        {programData.program === ASSOCIATION_PROGRAM && (
          <AssociationInput
            isCreate={!props.isUpdate}
            programData={programData}
            onProgramDataChange={onProgramDataChange}
            onAttributesFilledChange={onAttributesFilledChange}
          />
        )}
      </div>

      {programData.entryPoint && (
        <div className={classNames(styles.responseMessage, "kat-col-xs-12")}>
          {showUrlUpdated && (
            <KatStatusindicator label={statusLabel} variant="success" />
          )}
          <div className={classNames(styles.linkDiv)}>
            <CopyLink
              className={styles.entryPointMessage}
              link={programData.entryPoint}
            />
            <CopyTooltip
              className={styles.copyDiv}
              copyText={programData.entryPoint}
              position="right"
            />
          </div>
        </div>
      )}

      {error && (
        <KatAlert
          className={classNames(styles.errorAlert, "kat-col-xs-12")}
          description={`${t("status-code")}: ${error.statusCode}`}
          header={error.message}
          variant="warning"
        ></KatAlert>
      )}

      <div slot="footer" className={classNames("kat-group-horizontal")}>
        {!props.isUpdate && (
          <KatButton
            id="clearButton"
            variant="secondary"
            label={t("clear-button-label")}
            onClick={handleClearEvent}
            disabled={isLoadingEnabled}
          >
            <KatIcon name="clear" size="tiny" slot="icon" />
          </KatButton>
        )}
        <KatButton
          id="principalActionButton"
          className={classNames({
            [styles.principalButtonDisabled]:
              isPrincipalButtonDisabled || isLoadingEnabled
          })}
          label={mode}
          loading={isLoadingEnabled}
          disabled={isPrincipalButtonDisabled}
          onClick={handlePrincipalEvent}
        >
          <KatIcon
            name={props.isUpdate ? "edit" : "plus"}
            size="tiny"
            slot="icon"
          />
        </KatButton>
        {programData.program === ASSOCIATION_PROGRAM && programData["associationName"] && (
          <AssociationNameModal
            isModalVisible={isEnableAssociationNameModal}
            programData={programData}
            handleClose={handleAssociationNameModalClose}
            handleSubmit={handleAssociationNameModalSubmit}
          />
        )}
      </div>
    </KatModal>
  );
}
