class AssociationLeadType {
  readonly name: string;
  readonly value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}

const STANDARD = new AssociationLeadType(
  "Standard AAP Lead Account",
  "STANDARD"
);
const PARTNER = new AssociationLeadType("Partner Lead Account", "PARTNER");

const AVAILABLE_ASSOCIATION_LEAD_TYPES: AssociationLeadType[] = [
  STANDARD,
  PARTNER
];

export const ASSOCIATION_LEAD_TYPE = AVAILABLE_ASSOCIATION_LEAD_TYPES.map(
  type => ({
    name: type.name,
    value: type.value
  })
);
