export function redirect(url: string): void {
  window.location.href = url;
}

export function open(url: string): void {
  window.open(url);
}

export function getURL(): string {
  return window.location.href;
}

export function getOrigin(): string {
  return window.location.origin;
}

export function getLocalStorage(): Storage {
  return localStorage;
}
