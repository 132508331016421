import { ProgramData } from "../../common/Program";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import { KatButton, KatIcon, KatModal } from "@amzn/katal-react";
import React from "react";

interface AssociationNameModalProps {
  isModalVisible: boolean;
  programData: ProgramData;
  handleClose: () => void;
  handleSubmit: () => void;
}

export function AssociationNameModal(
  props: AssociationNameModalProps
): JSX.Element {
  const { t } = useTranslation();

  return (
    <KatModal
      id="associationNameModal"
      no-close-icon
      title={t("association-name-modal-warning-title")}
      visible={props.isModalVisible}
    >
      <p id="assocationNameModalMessage">
        <div>
          {t("association-name-modal-warning-message1")}
          <b>{capitalizeFirstLetter(props.programData["associationName"])}</b>
        </div>
        <div>{t("association-name-modal-warning-message2")}</div>
      </p>

      <div slot="footer" className="kat-group-horizontal">
        <KatButton
          id="closeButton"
          variant="secondary"
          label={t("association-name-modal-cancel-button-label")}
          onClick={props.handleClose}
        >
          <KatIcon name="cancel" size="tiny" slot="icon" />
        </KatButton>
        <KatButton
          id="proceedButton"
          label={t("association-name-modal-proceed-button-label")}
          onClick={props.handleSubmit}
        ></KatButton>
      </div>
    </KatModal>
  );
}
