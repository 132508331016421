import classNames from "classnames";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { KatDropdown, KatInput } from "@amzn/katal-react";
import { ProgramData } from "../../common/Program";
import { StageContext } from "../../App";
import { getPartnerId } from "../../common/ProgramConfig";
import { ASSOCIATION_LEAD_TYPE } from "../../common/AssociationLeadType";

interface AssociationInputProps {
  isCreate: boolean;
  programData: ProgramData;
  onProgramDataChange: (
    value: ProgramData | ((prevState: ProgramData) => ProgramData)
  ) => void;
  onAttributesFilledChange: (value: boolean) => void;
}

const FORM_STYLE = "kat-col-xs-6";

export function AssociationInput(props: AssociationInputProps): JSX.Element {
  const { t } = useTranslation();
  const stage = useContext(StageContext);
  const {
    programData: {
      campaignId,
      partnerId,
      segmentId,
      marketplaceId,
      associationName,
      associationLeadType
    },
    isCreate,
    onProgramDataChange,
    onAttributesFilledChange
  } = props;

  const defaultPartnerId = useMemo(() => getPartnerId(stage, marketplaceId), [
    marketplaceId,
    stage
  ]);

  useEffect(() => {
    if (isCreate)
      onProgramDataChange(prevState => {
        return { ...prevState, partnerId: defaultPartnerId };
      });
  }, [isCreate, onProgramDataChange, defaultPartnerId]);

  useEffect(() => {
    onAttributesFilledChange(isCreate ? !!segmentId : true);
  }, [onAttributesFilledChange, isCreate, segmentId]);

  const handleOnInputChange = name => event => {
    onProgramDataChange({
      ...props.programData,
      [name]: event.target.value.trim()
    });
  };

  const handleOnInputCampaignIdChange = handleOnInputChange("campaignId");
  const handleOnInputSegmentIdChange = handleOnInputChange("segmentId");
  const handleOnInputAssociationNameChange = handleOnInputChange(
    "associationName"
  );
  const handleOnSelectAssociationTypeChange = handleOnInputChange(
    "associationLeadType"
  );

  return (
    <>
      <KatInput
        id="partnerIdInput"
        className={classNames(FORM_STYLE)}
        label={t("partnerid-input-label")}
        type="search"
        value={partnerId}
        disabled={true}
      />
      {props.isCreate && (
        <KatInput
          id="segmentIdInput"
          className={classNames(FORM_STYLE)}
          label={`${t("segmentid-input-label")} *`}
          type="search"
          value={segmentId}
          onInput={handleOnInputSegmentIdChange}
        />
      )}
      <KatInput
        id="campaignIdInput"
        className={classNames(FORM_STYLE)}
        label={t("campaignid-input-label")}
        type="search"
        value={campaignId}
        onInput={handleOnInputCampaignIdChange}
      />
      <KatInput
        id="associationNameInput"
        className={classNames(FORM_STYLE)}
        label={t("association-name-input-label")}
        type="search"
        value={associationName}
        onInput={handleOnInputAssociationNameChange}
        constraint-emphasis={t("association-name-input-constraint-emphasis")}
        constraint-label={t("association-name-input-constraint-label")}
        maxlength={50}
      />
      <KatDropdown
        id="associationLeadTypeDropdown"
        className={classNames(FORM_STYLE)}
        label={t("association-lead-type-dropdown-label")}
        options={ASSOCIATION_LEAD_TYPE}
        value={associationLeadType}
        onChange={handleOnSelectAssociationTypeChange}
      />
    </>
  );
}
